import { Button, Col, Row } from "antd";
import ExchangeInput from "./ExchangeInput";
import ExchangeType from "./ExchangeType";

import { SwapOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import api from "../services/api";
import { BigNumber, ethers } from "ethers";

import web3 from "web3";

import abi from "../abi/abi";

function ExchangeComponent() {
  const [swapType, setSwapType] = useState("eth");
  const [getCostValue, setGetCostValue] = useState("0");

  const [selectedAddress, setSelectedAddress] = useState();

  const [apiInfo, setApiInfo] = useState();

  useEffect(() => {
    api
      .getInfo()
      .then((response) => {
        setApiInfo(response.data);
      })
      .catch((err) => {});
  }, []);

  const connectToMetamask = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const accounts = await provider.send("eth_requestAccounts", []);

    setSelectedAddress(accounts[0]);
  };

  useEffect(() => {
    connectToMetamask();
  }, []);

  const onExchange = async () => {
    if (apiInfo == undefined) {
      return;
    }

    if (swapType == "eth") {
      //GOERLI - 5, MAINNET - 1
      await changeNetwork(1);

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      await provider.send("eth_requestAccounts", []);

      // The MetaMask plugin also allows signing transactions to
      // send ether and pay to change state within the blockchain.
      // For this, you need the account signer...
      const signer = provider.getSigner();

      const mzrContractInstance = new ethers.Contract(
        apiInfo.data.contractAddressEth,
        abi,
        signer
      );

      var value = ethers.utils.parseUnits(getCostValue, 18)

      var transferTransaction = mzrContractInstance.transfer(apiInfo.data.destinationAddress, value, {
        gasLimit: BigNumber.from(Math.pow(10, 4).toString()).mul(
          BigNumber.from(21)
        ),
      });

      await waitUntilTransactionConfirmed(provider, transferTransaction);
    }

    if (swapType == "okc") {
      //OKC Testnet - 65, OKC Mainnet - 66
      await changeNetwork(66);

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      await provider.send("eth_requestAccounts", []);

      // The MetaMask plugin also allows signing transactions to
      // send ether and pay to change state within the blockchain.
      // For this, you need the account signer...
      const signer = provider.getSigner();

      const mzrContractInstance = new ethers.Contract(
        apiInfo.data.contractAddressOkc,
        abi,
        signer
      );

      var value = ethers.utils.parseUnits(getCostValue, 18)

      var transferTransaction = mzrContractInstance.transfer(apiInfo.data.destinationAddress, value, {
        gasLimit: BigNumber.from(Math.pow(10, 4).toString()).mul(
          BigNumber.from(21)
        ),
      });

      await waitUntilTransactionConfirmed(provider, transferTransaction);
    }
  };

  const changeNetwork = async (chainId) => {
    if (window.ethereum.networkVersion !== chainId) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3.utils.toHex(chainId) }],
        });
      } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
        }
      }
    }
  };

  async function waitUntilTransactionConfirmed(provider, hash) {
    return await new Promise((resolve) => {
      const interval = setInterval(() => {
        getTransaction(provider, hash).then((transaction) => {
          console.log(transaction);

          if (transaction.confirmations >= 1) {
            resolve(transaction);
            clearInterval(interval);
          }
        });
      }, 1000);
    });
  }

  async function getTransaction(provider, hash) {
    return await provider.getTransaction(hash);
  }

  return (
    <div>
      {selectedAddress == null ? (
        <Row style={{ marginBottom: 20 }} justify={"center"}>
          <Button
            onClick={() => {
              connectToMetamask();
            }}
            color="#041139"
          >
            Connect MetaMask
          </Button>
        </Row>
      ) : (
        <div
          style={{
            height: 60,
            minWidth: "45vw",
            border: "1px solid white",
            borderRadius: 10,
            background: "#041139",
          }}
        >
          <Row style={{ height: "100%", width: "100%" }}>
            <Col span={6} style={{ borderRight: "1px solid white" }}>
              <ExchangeInput
                onChange={(event) => {
                  let price = event.target.value;

                  if (price === "") {
                    setGetCostValue();
                    return;
                  }

                  let network = swapType == "eth" ? "okc" : "eth";

                  api
                    .getPrice(price, network)
                    .then((response) => {
                      if (!response.data.data.isAllowed) {
                        setGetCostValue(
                          response.data.error !== null
                            ? response.data.error
                            : "Error"
                        );
                        return;
                      }

                      setGetCostValue(response.data.data.resultAmount);
                    })
                    .catch((err) => {
                      setGetCostValue(
                        err.response.data.error !== null
                          ? err.response.data.error
                          : "Error"
                      );
                      return;
                    });

                  console.log();
                }}
                text={"You send:"}
              />
            </Col>
            <Col span={3} style={{ borderRight: "1px solid white" }}>
              <ExchangeType
                text={"MZR"}
                type={swapType == "eth" ? "ETH" : "OKC"}
              />
            </Col>
            <Col style={{ borderRight: "1px solid white" }}>
              <Row
                style={{ marginLeft: 5, marginRight: 5, height: "100%" }}
                align={"middle"}
              >
                <Button
                  onClick={() => {
                    if (swapType === "eth") {
                      setSwapType("okc");
                    } else {
                      setSwapType("eth");
                    }
                  }}
                  color="#041139"
                >
                  <SwapOutlined />
                </Button>
              </Row>
            </Col>
            <Col span={6} style={{ borderRight: "1px solid white" }}>
              <ExchangeInput
                value={getCostValue}
                readonly={true}
                text={"You get:"}
              />
            </Col>
            <Col span={3} style={{ borderRight: "1px solid white" }}>
              <ExchangeType
                text={"MZR"}
                type={swapType != "okc" ? "OKC" : "ETH"}
              />
            </Col>
            <Col span={4}>
              <Row
                style={{ height: "100%", maxWidth: "auto", width: "100%" }}
                align={"middle"}
                justify={"center"}
              >
                <Button
                  onClick={onExchange}
                  style={{ color: "#fff" }}
                  type="text"
                >
                  Exchange
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default ExchangeComponent;
