import { Col, Input, Row } from "antd";

function ExchangeInput({ value, readonly, text, onChange }) {
  return (
    <div style={{ marginLeft: 10, marginTop: 6 }}>
      <Row>
        <Col>
          <Row style={{ color: "white" }}>{text}</Row>
          <Row>
            <Input
              readOnly={readonly}
              onChange={onChange}
              style={{ color: "white" }}
              bordered={false}
              value={value}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ExchangeInput;
