import axios from "axios";

let api = {};

api.getPrice = (price, network) => {
  return axios.get(`https://mazuri-bridge.fgp-dev.me/api/v1/public/exchange?amount=${price}&to=${network}`);
};

api.getInfo = () => {
  return axios.get("https://mazuri-bridge.fgp-dev.me/api/v1/public/info", {});
};

export default api;
