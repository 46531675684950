import logo from "./logo.svg";
import "./App.css";
import { Button, Col, Row } from "antd";
import ExchangeComponent from "./components/ExchangeComponent";
import ExchangeDescription from "./components/ExchangeDescription";

function App() {
  return (
    <div className="App">
      <div
        style={{ left: 0, right: 0, top: 0, bottom: 0, position: "absolute" }}
      >
        <img
          style={{ height: "100%", width: "100%" }}
          src={require("./assets/images/background.jpg")}
        />
      </div>

      <div
        style={{
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row>
          <Col>
            <Row style={{ marginTop: 40 }}>
              <ExchangeComponent />
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default App;
