import { Col, Row } from "antd";

function ExchangeType({ text, type }) {
  return (
    <div style={{ height: "100%", marginTop: 6 }}>
      <Row style={{ height: "calc(100% - 5px)", width: "100%" }}>
        <Col style={{ width: "100%" }}>
          <Row
            justify={"center"}
            align={"middle"}
            style={{
              color: "white",
              height: "calc(100% - 11px)",
              fontWeight: "bold",
            }}
          >
            {text}
          </Row>
          <Row
            justify={"center"}
            align={"middle"}
            style={{
              color: "#041139",
              fontSize: 10,
              background: "white",
            }}
          >
            {type}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ExchangeType;
